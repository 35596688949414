<template>
	<v-app>
		<template v-if="isSubmitted || (quotationData.status != 5) || isRejected">
			<v-col v-if="isSubmitted || (quotationData.status != 5)" md="12" class="px-10 py-0">
				<div class="quotation-approved my-20">
					<div class="card">
						<div class="crossBtn" v-on:click="closeTab">
							<i class="crossIcon">𐤕</i>
						</div>
						<div
							style="
								border-radius: 200px;
								height: 200px;
								width: 200px;
								background: #f8faf5;
								margin: 0 auto;
							"
						>
							<i class="checkmark">✓</i>
						</div>
						<h1>Success</h1>
						<p v-if = "quotationData.status != 5">
							Quotation #
							{{ quotationData.barcode }}<br />
							Already Accepted!<br />
							Thank You!
						</p>
						<p v-else>
							We received your approval against Quotation #
							{{ quotationData.barcode }}<br />
							we'll be in touch shortly!<br />
							Thank You!
						</p>
						
					</div>
				</div>
			</v-col>
			<v-col v-if="isRejected || (quotationData.status != 5)" md="12" class="px-10 py-0">
				<div class="quotation-approved my-20">
					<div class="card">
						<div class="crossBtn" v-on:click="closeTab">
							<i class="crossIcon">𐤕</i>
						</div>
						<div
							style="
								border-radius: 200px;
								height: 200px;
								width: 200px;
								background: #f8faf5;
								margin: 0 auto;
							"
						>
							<i class="checkmark">✓</i>
						</div>
						<h1>Success</h1>
						<p v-if = "quotationData.status != 5">
							Quotation #
							{{ quotationData.barcode }}<br />
							Already Accepted!<br />
							Thank You!
						</p>
						<p v-else>
							We received your rejection against Quotation #
							{{ quotationData.barcode }}<br />
							we'll be in touch shortly!<br />
							Thank You!
						</p>
						
					</div>
				</div>
			</v-col>
			
		</template>
		<div v-if="!isSubmitted" class="checkout-aprove-container px-10 py-8">
			<v-row class="border-bottom">
				<v-col cols="12">
					<img :src="$assetURL('media/logos/logo-genic.png')" alt="Espirit-logo" />
				</v-col>
			</v-row>
			<v-row class="mb-4">
				<v-col cols="12" class="py-0">&nbsp;</v-col>

				<v-col cols="8" class="pl-0 py-0 font-size-14">
					<table width="100%" class="detail-table table-head-sticky" border="0">
						<tr>
							<td class="pl-0 pb-0" width="50" valign="top">To</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0" valign="top">
								<span class="font-size-14 fw-500" v-if="quotationData.customer">{{
									quotationData.customer.company_name
								}}</span>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="50" valign="top">Address</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.unit_number"
								>
									{{ quotationData.customer_default_address.unit_number }}
								</p>
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.address_line_1"
								>
									{{ quotationData.customer_default_address.address_line_1 }}
								</p>
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.address_line_2"
								>
									{{ quotationData.customer_default_address.address_line_2 }}
								</p>
								<p class="font-size-14 fw-500 m-0">
									<template v-if="quotationData.customer_default_address.address_country">
										{{ quotationData.customer_default_address.address_country }}
									</template>
									<template v-if="quotationData.customer_default_address.address_postal_code">
										{{ quotationData.customer_default_address.address_postal_code }}
									</template>
								</p>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="50" valign="top">Attention</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.display_name"
								>
									{{ quotationData.customer_default_address.display_name }}
								</p>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="150" valign="top">Contact Number</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.display_name"
								>
									{{ quotationData.customer_default_address.phone_number }}
								</p>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="50" valign="top">Email</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p
									class="font-size-14 fw-500 m-0"
									v-if="quotationData.customer_default_address.display_name"
								>
									{{ quotationData.customer_default_address.email }}
								</p>
							</td>
						</tr>
					</table>
				</v-col>

				<v-col cols="4" class="pl-0 py-0 font-size-14">
					<table width="100%" class="detail-table table-head-sticky" border="0">
						<tr>
							<td class="pl-0 pb-0" width="100" valign="top">Quotation #</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0" valign="top">
								<span class="font-size-14 fw-500" v-if="quotationData.barcode">{{
									quotationData.barcode
								}}</span>
							</td>
						</tr>
						<tr v-if="quotationData.quotation_number">
							<td class="pl-0 pb-0" width="100" valign="top">Quotes Number</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0" valign="top">
								<span class="font-size-14 fw-500" v-if="quotationData.quotation_number">{{
									quotationData.quotation_number
								}}</span>
							</td>
						</tr>
						<tr v-if="quotationData.ticket_number">
							<td class="pl-0 pb-0" width="100" valign="top">Ticket Number</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0" valign="top">
								<span class="font-size-14 fw-500" v-if="quotationData.ticket_number">{{
									quotationData.ticket_number
								}}</span>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="100" valign="top">Date</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p class="font-size-14 fw-500 m-0" v-if="quotationData.date">
									{{ quotationData.date }}
								</p>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="100" valign="top">Prepared By</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p class="font-size-14 fw-500 m-0" v-if="quotationData.prepared_by">
									{{ quotationData.prepared_by }}
								</p>
							</td>
						</tr>
						<tr>
							<td class="pl-0 pb-0" width="100" valign="top">Staff Person</td>
							<td class="pl-0 pb-0" width="5px" valign="top">:</td>
							<td class="pl-0 pb-0">
								<p class="font-size-14 fw-500 m-0" v-if="quotationData.sales_person">
									{{ quotationData.sales_person.display_name }}
								</p>
							</td>
						</tr>
					</table>
				</v-col>
			</v-row>
			<v-row class="mb-4">
				<v-col cols="12" class="pl-0 pr-0">
					<table width="100%" style="margin-top: 5px">
						<tr>
							<td style="padding: 5px; border-bottom: 1px solid #042e76; border-top: 1px solid #042e76">
								<strong>Title: {{ quotationData.title }}</strong>
							</td>
						</tr>
					</table>
				</v-col>
			</v-row>

			<v-row class="mt-4">
				<v-col cols="12" class="pl-0 pr-0">
					<table class="approve_table_border w-100 font-size-14">
						<tr v-if="quotationData.description">
							<td colspan="8">
								<b>{{ quotationData.description }}</b>
							</td>
						</tr>
						<tr>
							<th width="40" class="text-center">S.N.</th>
							<th>Item</th>
							<th width="120" class="text-center">Description</th>
							<th width="60" class="text-center">UOM</th>
							<th>QTY</th>
							<th>Rate</th>
							<th>Discount</th>
							<th>Amount</th>
						</tr>
						<tr v-for="(row, index) in quotationData.line_items" :key="index">
							<td widtd="40" class="text-center">{{ index + 1 }}</td>
							<td>
								<template v-if="row && row.original_product">{{ row.original_product.name }}</template>
							</td>
							<td>
								<template v-if="row && row.description">{{ row.description }}</template>
							</td>
							<td widtd="100" class="text-center">
								<template v-if="row && row.uom">{{ row.uom }}</template>
							</td>
							<td widtd="60" class="text-center">{{ row.quantity }}</td>
							<td class="text-right">
								<template v-if="row && row.rate">{{ formatMoney(row.rate ? row.rate : 0) }}</template>
							</td>
							<td class="text-right">
								<template v-if="row && row.discount">{{ formatMoney(row.discount) }}</template>
							</td>
							<td class="text-right">
								<template v-if="row && row.total">{{ formatMoney(row.total) }}</template>
							</td>
						</tr>

						<tfoot style="font-size: 14px">
							<tr>
								<td colspan="7" class="p-2 text-right"><b>Sub Total</b></td>
								<td class="text-right">
									<b>{{ formatMoney(quotationData.sub_total) }}</b>
								</td>
							</tr>
							<tr>
								<td colspan="7" class="p-2 text-right">
									<b
										>goodwill discount (
										{{ quotationData.discount_value_type == 1 ? "$" : "" }}
										{{ quotationData.discount_value }}
										{{ quotationData.discount_value_type == 2 ? "%" : "" }}
										)</b
									>
								</td>
								<td class="text-right">
									<b>{{ formatMoney(quotationData.discount_amount) }}</b>
								</td>
							</tr>
							<tr v-if="quotationData.tax_applied">
								<td colspan="7" class="p-2 text-right">
									<b>Tax {{ quotationData.tax_value }}%</b>
								</td>
								<td class="text-right">
									<b>{{ formatMoney(quotationData.tax_amount) }}</b>
								</td>
							</tr>
							<tr>
								<td colspan="7" class="p-2 text-right"><b>Adjustment</b></td>
								<td class="text-right">
									<b>{{ formatMoney(quotationData.adjustment) }}</b>
								</td>
							</tr>
							<tr>
								<td colspan="7" class="p-2 text-right green--text"><b>Grand Total</b></td>
								<td class="text-right green--text">
									<b>{{ formatMoney(quotationData.total) }}</b>
								</td>
							</tr>
						</tfoot>
					</table>
				</v-col>
			</v-row>

			<v-row class="mt-4">
				<v-col cols="12" class="pl-0 pr-0">
					<div>
						<label class="border-bottom">
							<b>Notes: </b>
						</label>
					</div>
					<div>{{ quotationData.notes }}</div>
				</v-col>
			</v-row>
			<v-row class="mt-3">
				<v-col cols="12" class="pl-0 pr-0">
					<div>
						<label class="border-bottom">
							<b>Terms & Conditions: </b>
						</label>
					</div>
					<div>{{ quotationData.terms_and_conditions_text }}</div>
				</v-col>
			</v-row>
			<v-row class="mt-4">
				<v-col cols="12" class="pl-0 pr-0">
					<table class="w-100 font-size-14">
						<tr>
							<td width="50%" height="100" class="border-bottom-color-none" style="position: relative">
								&nbsp;
							</td>
							<td width="50%" class="border-bottom-color-none" style="position: relative">
								<div class="signature-pad" id="signature-pad">
									<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
										<canvas ref="cc_signature1"></canvas>
									</div>
								</div>
								<v-btn
									v-if="!orderData.approver_signature"
									small
									v-on:click="clear_signature('approver_signature')"
									text
									outlined
									style="position: absolute; bottom: 10px; right: 20px"
									><v-icon>mdi-close</v-icon></v-btn
								>
							</td>
						</tr>
						<tr>
							<td width="50%">&nbsp;</td>
							<td width="50%"><b>Accepted by</b></td>
						</tr>

						<tr>
							<td width="50%" colspan="2" class="text-right">
								<v-btn
									tile
									depressed
									:loading="pageLoading"
									:disabled="pageLoading"
									v-on:click="rejectDialog = true"
									class="mr-4"
								>
									Reject
								</v-btn>

								<v-btn
									tile
									depressed
									v-on:click="approveQuotation('Accept')"
									:loading="pageLoading"
									:disabled="pageLoading"
									class="blue darken-4 white--text"
								>
									Accept
								</v-btn>
							</td>
						</tr>
					</table>
				</v-col>
			</v-row>
			<div v-if="errors.length">
				<v-alert class="errors" v-for="(error, index) in errors" :key="index">
					{{ error }}
				</v-alert>
			</div>
		</div>
		<Dialog :dialog="rejectDialog" :dialog-width="600">
			<template v-slot:title> Reject </template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="12" class="py-0 red--text"> Are you sure want to reject  </v-col>
					<v-col md="12" class="py-0">
						<TextAreaInput
							:disabled="pageLoading"
							:loading="pageLoading"
							id="reject_remark"
							placeholder="Enter Remark"
							:rules="[vrules.required(reject_remark, 'Remark')]"
							:class="{
								required: !reject_remark,
							}"
							v-model="reject_remark"
						></TextAreaInput>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="rejectDialog=false">
					No, Close
				</v-btn>
				<slot name="extra-btn"></slot>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="approveQuotation('Reject')"
				>
					Yes! Reject
				</v-btn>
			</template>
		</Dialog>
	</v-app>
	
</template>

<style type="text/css" scoped>
.v-alert.v-sheet.errors {
	position: fixed;
	top: 10px;
	right: 10px;
	background-color: #ffe3e3;
	color: #b31919;
}
.quotation-approved h1 {
	color: #88b04b;
	font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
	font-weight: 900;
	font-size: 40px;
	margin-bottom: 10px;
}
.quotation-approved p {
	color: #404f5e;
	font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
	font-size: 20px;
	margin: 0;
}
.quotation-approved i {
	color: #9abc66;
	font-size: 100px;
	line-height: 200px;
	margin-left: -15px;
}
.quotation-approved .card {
	background: white;
	padding: 60px;
	border-radius: 4px;
	box-shadow: 0 2px 3px #c8d0d8;
	display: inline-block;
	margin: 0 auto;
}
.quotation-approved {
	text-align: center;
	padding: 40px 0;
	background: #ebf0f5;
}
.crossBtn {
	position: absolute;
	left: auto;
	right: 25px;
	top: 30px;
	width: 35px;
	cursor: pointer;
	background-color: red;
	padding: 6px;
	padding-top: 10px;
	border-radius: 30px;
}
.crossIcon {
	font-size: 20px !important;
	font-weight: 600 !important;
	color: #fff !important;
	margin: 0 !important;
	padding: 0 !important;
	line-height: 0 !important;
}
</style>

<script>
import SignaturePad from "signature_pad";

import { approveQuotationByCustomer } from "@/core/lib/setting.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
export default {
	name: "quotation-approve",
	props: {
		uuid: {
			type: String,
			default: null,
		},
		details: {
			type: Object,
			default: new Object(),
		},
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param && this.uuid) {
					this.init(param);
				}
			},
		},
	},
	data() {
		return {
			errors: [],
			checkin: [],
			memberData: [],
			orderData: {},
			quotationData: {},
			isSubmitted: false,
			isRejected: false,
			pageLoading: false,
			requester_signature: null,
			approver_signature: null,
			rejectDialog: false,
			reject_remark: null
		};
	},
	methods: {
		clear_signature(type) {
			const signature_pad = this[type];
			if (signature_pad) {
				signature_pad.clear();
			}
		},
		initSignature() {
			const _this = this;
			/* let ccanvas = _this.$refs["cc_signature"];
			let ccanvasDiv = _this.$refs["signature_div"];
			ccanvas.setAttribute("width", 400);
			ccanvasDiv.setAttribute("style", "width:" + "400px"); */
			//_this.requester_signature = new SignaturePad(ccanvas);

			let ccanvas = _this.$refs["cc_signature1"];
			let ccanvasDiv = _this.$refs["signature_div"];
			ccanvas.setAttribute("width", 400);
			ccanvasDiv.setAttribute("style", "width:" + "400px");
			_this.approver_signature = new SignaturePad(ccanvas);

			/* if (_this.quotationData.admin_signature) {
				_this.requester_signature.fromDataURL(_this.quotationData.admin_signature);
				_this.requester_signature.off();
			} */
		},
		init(data) {
			const _this = this;
			_this.quotationData = data;
		},
		approveQuotation(status) {
			const _this = this;
			_this.errors = [];
			if (status == 'Accept' && _this.approver_signature.isEmpty()) {
				_this.errors.push(" Signature is required.");
				return false;
			}
			/* if (_this.requester_signature.isEmpty()) {
				_this.errors.push("Approved by signature is required.");
				return false;
			} */
			_this.pageLoading = true;

			let approverSignatureUrl = _this.approver_signature.toDataURL();

			//let requesterSignatureUrl = _this.requester_signature.toDataURL();

			approveQuotationByCustomer({
				quotation: _this.uuid,
				approver_signature: approverSignatureUrl,
				status: status,
				reject_remark: _this.reject_remark
				//requester_signature: requesterSignatureUrl,
			})
				.then((data) => {
					console.log(data);
					if(status == 'Accept'){
						_this.isSubmitted = true;

					}
					if(status == 'Reject'){
						_this.isRejected= true;
						_this.rejectDialog = false;
					}
					
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeTab() {
			window.close();
		},
	},
	computed: {
		currentDate() {
			const today = new Date();
			return this.formatDate(today);
		},
	},
	mounted() {
		this.initSignature();
		if (!this.uuid) {
			this.goBack();
		}
	},
	components: {
		Dialog,
		TextAreaInput
	},
};
</script>
